const body = document.body;

const UP = -1;
const DOWN = 1;

let friction = 0.7;
let acceleration = 0.04;

let positionY = 100;
let velocityY = 0;
let targetPositionY = 400;

let raf = null;

function getScrollTop() {
    return document.body.scrollTop || document.documentElement.scrollTop;
}

function animate() {
    const distance = update();
    render();

    if (Math.abs(distance) > 0.1) {
        raf = requestAnimationFrame(animate);
    }
}

function update() {
    const distance = targetPositionY - positionY;
    const attraction = distance * acceleration;

    applyForce(attraction);

    velocityY *= friction;
    positionY += velocityY;

    return distance;
}

function applyForce(force) {
    velocityY += force;
}

function render() {
    window.scrollTo(0, positionY);
}

window.addEventListener('mousewheel', event => {
    if (raf) {
        cancelAnimationFrame(raf);
        raf = null;
    }
}, {
    passive: true
});


export default function scrollTo(offset, callback) {
    positionY = getScrollTop();
    targetPositionY = offset;
    velocityY = 0;
    animate();
}
