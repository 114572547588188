import Highcharts from 'highcharts'

import Component from '../core/Component'

export default class ChartBar extends Component {

    prepare() {
        const options = {
            chart: {
                type: this.options.type || 'column',
                backgroundColor: 'transparent',
                style: {
                    color: '#00319E',
                    fontFamily: '"Univers LT CYR 45", sans-serif'
                },
                borderColor: '#00319E',
            },
            credits: {
                enabled: false
            },
            legend: {
                enabled: false,
            },
            colors: this.options.colors || ['#D0B6FE', '#9257FD', '#6410FC'],
            title: false,
            plotOptions: {
                column: {
                    colors: this.options.columnColors || ['#D0B6FE', '#9257FD', '#6410FC'],
                    colorByPoint: this.options.colorByPoint || false,
                    borderWidth: 0,
                    dataLabels: {
                        verticalAlign: this.options.labelsVerticalAlign || 'bottom',
                        enabled: this.options.labelsEnabled || false,
                        color: '#00319E',
                        style: {
                            textOutline: 'none',
                            fontSize: '30px',
                            fontFamily: 'KPMG Webfont, sans-serif',
                            fontWeight: 'bold'
                        },
                        formatter: function () {
                            return Highcharts.numberFormat(this.y, 1).replace('.', ',');
                        }
                    }
                },
                line: {
                    marker: {
                        symbol: this.options.lineMarker || null
                    }
                }
            },
            xAxis: {
                categories: this.options.categories,
                labels: {
                    style: {
                        color: '#00319E',
                        fontSize: "15px"
                    },
                },
                lineColor: '#00319E',
                lineWidth: 1,
                tickColor: '#00319E',
                type: 'category'
            },
            yAxis: {
                title: {
                    enabled: false
                },
                labels: {
                    style: {
                        color: '#00319E',
                        fontSize: "15px"
                    },
                    formatter: function() {
                        if (this.value % 0.5 === 0) {
                            return this.value.toString().replace('.', ',')
                        }

                        return ''
                    }
                },
                allowDecimals: false,
                gridLineWidth: 1,
                gridLineColor: '#EAEBEE',
                lineWidth: 1,
                lineColor: '#1E49E2',
                tickInterval: 0.1,
            },
            tooltip: {
                pointFormat: this.options.customToolTip ? '<b>{series.name}</b> - <b>{point.y}</b> <span style="color:{point.custom.color};"> {point.custom.percentage}</span>' : '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b>'
            },
            series: this.options.series
        }

        if (this.options.min) {
            options.yAxis.min = this.options.min
        }

        if (this.options.max) {
            options.yAxis.max = this.options.max
        }

        this.chart = Highcharts.chart(this.element, options)
    }

}
