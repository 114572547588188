import enquire from 'enquire.js'
import throttle from 'lodash/throttle'

import Component from '../core/Component'
import { queries } from '../core/config'
import EventBus from '../core/EventBus'
import Scroll, { getScrollTop } from '../services/Scroll'

export const STATES = {
    STICKY: 'is-sticky'
}

const defaults = {
    media: null
}

export default class Sticky extends Component {
    constructor(element) {
        super(element)

        this.options = {
            top: 0
        }

        const top = parseInt(this.element.dataset.stickyTop)

        if (!isNaN(top)) {
            this.options.top = top
        }

        this.isSticky = false

        this.handleResize = throttle(this.handleResize, 100)

        if (this.options.media) {
            this.options.media = this.options.media in queries ? queries[this.options.media] : this.options.media
        }

        this.enquireHandler = {
            match: this.attach,
            unmatch: this.detach
        }
    }

    prepare() {
        if (this.options.media) {
            enquire.register(this.options.media, this.enquireHandler)
        } else {
            this.attach()
        }
    }

    destroy() {
        if (this.options.media) {
            enquire.unregister(this.options.media, this.enquireHandler)
        } else {
            this.detach()
        }
    }

    attach = () => {
        this.parent = this.element.parentNode

        Scroll.on('scroll', this.handleScroll)
        Scroll.on('resize', this.handleResize)
        EventBus.on('tabs:change', this.handleResize)

        this.resize()
        this.render()
    }

    detach = () => {
        Scroll.off('scroll', this.handleScroll)
        Scroll.off('resize', this.handleResize)
        EventBus.off('tabs:change', this.handleResize)
    }

    handleScroll = () => {
        this.render()
    }

    handleResize = () => {
        this.resize()
    }

    resize() {
        this.element.style.width = 'auto'

        const scrollTop = getScrollTop()

        const parentBox = this.parent.getBoundingClientRect()
        this.parentBox = {
            height: parentBox.height,
            width: parentBox.width,
            top: scrollTop + parentBox.top
        }

        const elementBox = this.element.getBoundingClientRect()
        this.elementBox = {
            height: elementBox.height,
            width: elementBox.width,
            top: scrollTop + elementBox.top
        }

        this.element.style.width = `${this.elementBox.width}px`
    }

    render() {
        const SCROLL_THRESHOLD = 2

        var offset = getScrollTop() - this.parentBox.top + this.options.top
        var max = this.parentBox.height - this.elementBox.height

        if (Math.abs(offset) < SCROLL_THRESHOLD) return

        if (offset < 0) {
            if (this.isSticky) {
                this.element.classList.remove(STATES.STICKY)
                this.isSticky = false
                this.element.style.transform = 'translateY(0px)'
            }
        } else if (offset > max) {
            if (this.isSticky) {
                this.element.classList.remove(STATES.STICKY)
                this.isSticky = false
                this.element.style.transform = `translateY(${max}px)`
            }
        } else {
            if (!this.isSticky) {
                this.element.classList.add(STATES.STICKY)
                this.isSticky = true
            }
            this.element.style.transform = `translateY(${offset}px)`
        }

    }
}
