import Choices from 'choices.js'

import Component from '../core/Component'

export default class Select extends Component {
    constructor(element) {
        super(element);

        this.ref = {
            select: null
        }
    }

    prepare() {

        const choices = new Choices(this.ref.select, {
            searchEnabled: false,
            itemSelectText: '',
            shouldSort: false,
            placeholder: true,
        });

        if (this.element.hasAttribute("data-redirect")) {
            this.element.addEventListener("change",:: this.handleChange)
        }

        if (this.options.header) {
            choices.passedElement.element.addEventListener('showDropdown', () => {
                document.documentElement.classList.add('dropdown-is-open')
            });

            choices.passedElement.element.addEventListener('hideDropdown', () => {
                document.documentElement.classList.remove('dropdown-is-open')
            });
        }
    }

    handleChange() {
        const url = this.ref.select.options[0].value
        window.location.href = url
    }
}
