import Highcharts from 'highcharts'

import Component from '../core/Component'

export default class ChartPillars extends Component {

    prepare() {
        const options = {
            chart: {
                type: this.options.type || 'column',
                backgroundColor: 'transparent',
                style: {
                    color: '#00319E',
                    fontFamily: '"Univers LT CYR 45", sans-serif'
                },
                borderColor: '#00319E',
                height: 300,
                spacingTop: 0
            },
            credits: {
                enabled: false
            },
            colors: this.options.colors || ['#D0B6FE', '#9257FD', '#6410FC'],
            title: false,
            plotOptions: {
                column: {
                    colors: this.options.columnColors || ['#D0B6FE', '#9257FD', '#6410FC'],
                    colorByPoint: this.options.colorByPoint || false,
                    borderWidth: 0,
                    dataLabels: {
                        inside: true,
                        verticalAlign: 'bottom',
                        align: 'center',
                        enabled: this.options.labelsEnabled || false,
                        color: '#fff',
                        style: {
                            textOutline: 'none',
                            fontSize: '20px',
                            fontFamily: 'KPMG Webfont, sans-serif',
                            fontWeight: 'bold',
                        },
                        formatter: function () {
                            return Highcharts.numberFormat(this.y, 1).replace('.', ',');
                        }
                    },
                    pointWidth: 30
                },
                series: {
                    events: {
                        legendItemClick: function(event) {
                            event.preventDefault();
                        }
                    }
                },
                line: {
                    marker: {
                        symbol: this.options.lineMarker || null
                    }
                }
            },
            xAxis: {
                categories: this.options.categories,
                type: 'category',
                labels: {
                    style: {
                        color: '#1E49E2',
                    },
                },
            },
            yAxis: {
                title: {
                    enabled: false
                },
                labels: {
                    style: {
                        color: '#1E49E2',
                        fontSize: "15px"
                    },
                    formatter: function() {
                        return '';
                    }
                },
                allowDecimals: false,
                gridLineWidth: 0
            },
            series: this.options.series,
            legend: {
                enabled: true,
                useHTML: true,
                symbolPadding: 0,
                symbolWidth: 0,
                symbolHeight: 0,
                squareSymbol: false,
             },

            tooltip: {
                enabled: false
            }
        }

        if (this.options.min) {
            options.yAxis.min = this.options.min
        }

        if (this.options.max) {
            options.yAxis.max = this.options.max
        }

        this.chart = Highcharts.chart(this.element, options)
    }

}
