import Component from '../core/Component'

export default class NavOpener extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            label: null
        }
    }

    prepare() {
        this.element.addEventListener('click',::this.handleClick)
    }

    destroy() {
        this.element.removeEventListener('click',::this.handleClick)
    }

    handleClick() {
        document.documentElement.classList.toggle('nav-is-open')
        document.querySelector(".Navigation").classList.toggle('is-active')
        document.querySelector(".Navigation-overlay").classList.toggle('is-active')

        if (this.ref.label) {
            if (document.documentElement.classList.contains('nav-is-open')) {
                if (document.documentElement.lang === "cs") {
                    this.ref.label.textContent = "Zavřít";
                } else {
                    this.ref.label.textContent = "Close";
                }
            } else {
                this.ref.label.textContent = "Menu";
            }
        }
    }
}
