import Highcharts from 'highcharts'

import Component from '../core/Component'

export default class ChartBarPercentage extends Component {

    prepare() {
        const isPercentage = this.options.format === 'percentage'
        var isEngVerze = window.location.href.indexOf('/en') != -1;

        const options = {
            chart: {
                type: this.options.type || 'column',
                backgroundColor: 'transparent',
                style: {
                    color: '#00319E',
                    fontFamily: '"Univers LT CYR 45", sans-serif'
                },
                borderColor: '#00319E',
            },
            credits: {
                enabled: false
            },
            legend: {
                itemStyle: {
                    color: '#00319E'
                }
            },
            colors: this.options.colors || ['#D0B6FE', '#9257FD', '#6410FC'],
            title: false,
            plotOptions: {
                column: {
                    colors: this.options.columnColors || ['#D0B6FE', '#9257FD', '#6410FC'],
                    colorByPoint: this.options.colorByPoint || false,
                    borderWidth: 0,
                    dataLabels: {
                        verticalAlign: this.options.labelsVerticalAlign || 'bottom',
                        enabled: this.options.labelsEnabled || false,
                        color: '#00319E',
                        formatter: function () {
                            function withPercantage() {
                                return isEngVerze ? '%' : ' %';
                            }
                            if (isPercentage) {
                                return Highcharts.numberFormat(this.y, 1) + (isPercentage ? withPercantage() : '')
                            } else {
                                return Highcharts.numberFormat(this.y, 1) + (isPercentage ? ' %' : '')
                            }
                        },
                        style: {
                            textOutline: 'none'
                        }
                    }
                },
                line: {
                    marker: {
                        symbol: this.options.lineMarker || null
                    }
                }
            },
            xAxis: {
                categories: this.options.categories,
                labels: {
                    style: {
                        color: '#00319E'
                    },
                    y: 35
                },
                lineColor: '#00319E',
                lineWidth: 1,
                tickColor: '#00319E',
                type: 'category'
            },
            yAxis: {
                title: {
                    enabled: false
                },
                labels: {
                    style: {
                        color: '#00319E'
                    },
                    formatter: function () {
                        if (isPercentage && this.isFirst) {
                            return ''
                        }
                        function withPercantage() {
                            return isEngVerze ? '%' : ' %';
                        }
                        return isPercentage ? `${this.value}${withPercantage()}` : this.value.toString().replace('.', ',')

                    }
                },
                allowDecimals: false,
                gridLineDashStyle: 'dot',
                gridLineWidth: 1,
                gridLineColor: '#00319E',
                tickInterval: this.options.yAxisTickInterval || 1,
            },
            tooltip: {
                pointFormat: this.options.customToolTip ? '<b>{series.name}</b> - <b>{point.y}</b> <span style="color:{point.custom.color};"> {point.custom.percentage}</span>' : '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b>'
            },
            series: this.options.series
        }

        if (this.options.min) {
            options.yAxis.min = this.options.min
        }

        if (this.options.max) {
            options.yAxis.max = this.options.max
        }

        this.chart = Highcharts.chart(this.element, options)
    }

}
